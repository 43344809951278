// React
import React, { Component, useState } from 'react';

// Components
import { ServiceNavigation } from "Component/service-navigation/ServiceNavigation.jsx";
import ServiceBreadcrumbs from 'Component/ServiceBreadcrumbs.jsx';

// Polaris
import { AppLayout } from '@amzn/awsui-components-react';
import { BreadCrumbController } from 'Component/ServiceBreadcrumbs.jsx';

const DynamicAppLayout = (props) => {
  const [navigationOpen, setNavigationOpen] = useState(false);
  let defaultProps = {}

  if (props.page in BreadCrumbController) {
    defaultProps.breadcrumbs = <ServiceBreadcrumbs {...props} />
  }
  Object.assign(defaultProps, {
    navigation: <ServiceNavigation />,
    contentType: "default",
    navigationOpen: navigationOpen,
    onNavigationChange: ({detail}) => setNavigationOpen(detail.open),
    toolsHide: true,
    headerSelector: '#page-header',
    footerSelector: '#page-footer',
    disableContentPaddings: true,
  }, props)

  return (
    <div
      style={{
        overflow: 'hidden',
      }}>
      <AppLayout {...defaultProps} />
    </div>
  )
}

// Class ServiceAppLayout is the AppLayout component that is used in the pages.
// Implement like this: <ServiceAppLayout page='GenericNotFound'/>
class ServiceAppLayout extends Component {
  constructor(props) {
    super();
  }

  render() {
    return (
      <DynamicAppLayout {...this.props} />
    );
  }
}

export default ServiceAppLayout;