// React
import React, { Component, useState } from 'react';

// Components
import { ServiceNavigation } from "Component/service-navigation/ServiceNavigation.jsx";
import ServiceBreadcrumbs from 'Component/ServiceBreadcrumbs.jsx';

// Polaris
import { BreadCrumbController } from 'Component/ServiceBreadcrumbs.jsx';
import { AppLayout, Flashbar } from "@amzn/awsui-components-react";

const DynamicAppLayout = (props) => {
  const [navigationOpen, setNavigationOpen] = useState(false);
  let defaultProps = {}

  if (props.page in BreadCrumbController) {
    defaultProps.breadcrumbs = <ServiceBreadcrumbs {...props} />
  }
  Object.assign(defaultProps, {
    contentType: "default",
    navigation: <ServiceNavigation />,
    navigationOpen: navigationOpen,
    onNavigationChange: ({detail}) => setNavigationOpen(detail.open),
    toolsHide: true,
    headerSelector: '#page-header',
    footerSelector: '#page-footer',
    notifications: [
      <Flashbar
        items={[
          {
            type: "warning",
            dismissible: false,
            content:
              "Amazon Highly Confidential. This page contains sensitive information."
          },
        ]}
      />
    ],
    stickyNotifications: true
  }, props)

  return (
    <AppLayout
      {...defaultProps} />
  )
}

// Class MetricsAppLayout is the AppLayout component that is used in the pages.
// Implement like this: <MetricsAppLayout page='GenericNotFound'/>
class MetricsAppLayout extends Component {
  constructor(props) {
    super();
  }

  render() {
    return (
      <div
        style={{
          paddingTop: '60px',
          paddingBottom: '30px'
        }}>
        <DynamicAppLayout {...this.props} />
      </div>
    );
  }
}

export default MetricsAppLayout;