import { useCollection } from "@amzn/awsui-collection-hooks";
import { Header, Table } from "@amzn/awsui-components-react";
import { columnDefinitions, EmptyState } from "./table-config";

export const DelegatedToUserTable = ({ tableItems, isLoading }) => {
    const { items, collectionProps } = useCollection(
        tableItems,
        {
            filtering: {
                empty: (
                    <EmptyState>
                        <b>{"You have no delegators."}</b>
                    </EmptyState>
                )
            },
            sorting: {},
            selection: {}
        }
    );

    return (
        <div data-testid={"delegated-to-user-table"}>
            <Table
                {...collectionProps}
                items={items}
                header={
                    <Header
                        counter={`(${items.length})`}
                        >
                        {"Delegated to me"}
                    </Header>
                }
                columnDefinitions={columnDefinitions}
                loading={isLoading}
                loadingText={"Fetching your delegators"}
            />
        </div>
    );
};
