
//React
import React, {Component} from 'react';
import { Box, Grid, Icon, SpaceBetween } from '@amzn/awsui-components-react';

// Helpers
import getDefaultLocale from '../helpers/getDefaultLocale.js';

// Icons
import iGlobe from "../../media/icons/globe.svg"
import iFeedback from "../../media/icons/feedback.svg"

// Styles
import css from "../../styles/components/service-footer.scss";


export default class ServiceFooter extends Component {
    constructor(props) {
        super(props);
        this.state = {
            year: new Date().getFullYear()
        }
    }

    getLocale() {
        return getDefaultLocale();
    }

    render() {
        console.log(this.getLocale());
        return (
            <footer id="page-footer" className="awsui-grid">
                <Grid
                    gridDefinition={[
                        { colspan: 6},
                        { colspan: 4},
                        { colspan: 2},
                    ]}
                    className="awsc-footer-suffix awsui-row awsui-util-no-gutters"
                >
                    <Box padding={{ top: "xxs" }} textAlign="left" className="col-xxs-12 col-m-3 align-left">
                        <a rel="noopener noreferrer" href="https://w.amazon.com/bin/view/AWS/Teams/GlobalServicesSecurity/Engineering/CodeDefender/" target="_blank" >
                            <Icon url={iFeedback} style={{marginRight:"5px"}}></Icon>Contact Us
                        </a>
                        <a rel="noopener noreferrer" href="#">
                            <Icon url={iGlobe} style={{marginRight:"5px"}}></Icon>English (US)
                        </a>
                    </Box>
                    <Box padding={{ top: "xxs" }} textAlign="right" id="copyright">
                        © 2008 - {this.state.year}, Amazon Web Services, Inc. or its affiliates. All rights reserved.
                    </Box>
                    <Box padding={{ top: "xxs" }} textAlign="right">
                        <SpaceBetween direction="horizontal" size="xs">
                            <a rel="noopener noreferrer" href="http://aws.amazon.com/privacy/" target="_blank">Privacy Policy</a>
                            <a rel="noopener noreferrer" href="http://aws.amazon.com/terms/" target="_blank">Terms of Use</a>
                        </SpaceBetween>
                    </Box>
                </Grid>
            </footer>
        );
    }
}