// Polaris
import {
    Box,
    Button,
    Header,
    Icon,
    Link,
    SpaceBetween,
    Table,
    TextFilter,
    Pagination
} from '@amzn/awsui-components-react';
import { useCollection } from "@amzn/awsui-collection-hooks";
import { useEffect, useState } from 'react';
import { User } from '../../core/user';
import { getRequests, sendApprovalRequests, sendRejectionRequests, RepoRequstStatus } from '../../helpers/repos/repoRequests'

export const RepositoryRequestTable = ({isAdmin}) => {

    const getMatchesCountText = (count) => {
        return count === 1 ? `1 match` : `${count} matches`;
    }

    const rejectRepoRequests = async () => {
        try {
            await sendRejectionRequests(selectedRequests);
            getRepoRequests();
            // TODO: Display flash bar success message (after Kage's refactor)
        } catch (e) {
            // TODO: Display flash bar error (after Kage's refactor)
        }
    }

    const approveRepoRequests = async () => {
        try {
            await sendApprovalRequests(selectedRequests);
            getRepoRequests();
            // TODO: Display flash bar success message (after Kage's refactor)
        } catch (e) {
            // TODO: Display flash bar error (after Kage's refactor)
        }
    }

    const getRepoRequests = async () => {
        setSelectedRequests([])
        setLoading(true)

        // Request to backend for repo requests
        try {
            let response = await getRequests();
            setRepoRequests(response.requests);
        }
        catch (err) {
            // TODO: Display flash bar error (after Kage's refactor)
        }
        
        setLoading(false)
    }

    const getRenderedStatus = (status) => {
        if (status == RepoRequstStatus.PENDING) {
            return <p style={{ color: 'gray' }}><Icon name={"status-pending"} /> {status}</p>
        } else if (status == RepoRequstStatus.APPROVED) {
            return <p style={{ color: '#6baf35' }}><Icon name={"status-positive"} variant='success'/> {status}</p>
        } else if (status == RepoRequstStatus.REJECTED) {
            return <p style={{ color: '#fe5e64' }}><Icon name={"status-negative"} variant='error'/> {status}</p>
        }
    }

    const sortingFunction = (a , b) => {
        // Pending statuses should go first
        if (a.status == RepoRequstStatus.PENDING && b.status != RepoRequstStatus.PENDING) {
            return 1;
        } else if (a.status != RepoRequstStatus.PENDING && b.status == RepoRequstStatus.PENDING) {
            return -1;
        }

        // Sort by timestamp if both are pending and last modified at if both are not pending
        if (a.status == RepoRequstStatus.PENDING && b.status == RepoRequstStatus.PENDING) {
            // Within 'PENDING', keep actionable requests on top
            if (user.userId == a.requester && user.userId != b.requester) {
                return -1;
            } else if (user.userId != a.requester && user.userId == b.requester) {
                return 1;
            }

            if (new Date(a.timestamp) < new Date(b.timestamp)) {
                return 1;
            } else {
                return -1;
            }
        } else {
            if (new Date(a.lastModifiedAt) < new Date(b.lastModifiedAt)) {
                return 1;
            } else {
                return -1;
            }
        }
    }

    // Get user for disabling checkboxes
    const user = new User();

    // State
    const [selectedRequests, setSelectedRequests] = useState([]);
    const [loading, setLoading] = useState(false)
    const [repoRequests, setRepoRequests] = useState([])
    const sortDescending = true

    // Table collection
    const { items, collectionProps, filterProps, filteredItemsCount, paginationProps } = useCollection(
        repoRequests,
        {
            pagination: {
                pageSize: 10,
            },
            filtering: { },
            sorting: {
                defaultState: {
                    sortingColumn: {
                        id: "status",
                        header: "Status",
                        cell: item => getRenderedStatus(item.status),
                        sortingComparator: sortingFunction
                    },
                    isDescending: sortDescending
                }
            },
            selection: {}
        }
    );

    useEffect(() => {
        getRepoRequests();
    }, []);

    return  <Table
                {...collectionProps}
                header={
                    <Header
                        counter={`(${repoRequests.length})`}
                        actions={
                            isAdmin ?
                                <SpaceBetween direction='horizontal' size='s'>
                                    <Button variant='normal' onClick={rejectRepoRequests}>Reject</Button>
                                    <Button variant='primary' onClick={approveRepoRequests}>Approve</Button>
                                </SpaceBetween>
                            :
                            <></>
                        }
                    >
                        Repository Requests
                    </Header>
                }
                selectionType='multi'
                onSelectionChange={({ detail }) => {
                    setSelectedRequests(detail.selectedItems);
                }}
                isItemDisabled={(item) => 
                    item.status != RepoRequstStatus.PENDING || user.userId == item.requester
                }
                selectedItems={selectedRequests}
                ariaLabels={{
                    selectionGroupLabel: "Items selection",
                    allItemsSelectionLabel: ({ selectedItems }) =>
                        `${selectedItems.length} ${selectedItems.length === 1 ? "item" : "items"
                        } selected`,
                    itemSelectionLabel: ({ selectedItems }, item) =>
                        item.name
                }}
                items={items}
                loading={loading}
                loadingText='Loading Repository Requests'
                columnDefinitions={
                    [
                        {
                            id: "url",
                            header: "Url",
                            cell: item => <Link href="{item.url}">{item.url}</Link>,
                            isRowHeader: true
                        },
                        {
                            id: "approver",
                            header: "Approver",
                            cell: item => item.approver == "_" ? "N/A" : item.approver
                        },
                        {
                            id: "managerAlias",
                            header: "Manager",
                            cell: item => item.approvedFor,
                        },
                        {
                            id: "requester",
                            header: "Requested By",
                            cell: item => item.requester
                        },
                        {
                            id: "timestamp",
                            header: "Requested at",
                            cell: item => new Date(item.timestamp).toLocaleDateString()
                        },
                        {
                            id: "reason",
                            header: "Reason",
                            cell: item => item.reason
                        },
                        {
                            id: "status",
                            header: "Status",
                            cell: item => getRenderedStatus(item.status)
                        },
                        {
                            id: "lastUpdatedAt",
                            header: "Last updated",
                            cell: item => item.lastUpdatedAt ? new Date(item.lastUpdatedAt).toLocaleDateString() : "N/A"
                        }
                    ]
                }
                filter={
                    <TextFilter
                        {...filterProps}
                        countText={getMatchesCountText(filteredItemsCount)}
                        filteringPlaceholder="Find Repository Requests"
                    />
                }
                pagination={
                    <Box float="right">
                        <SpaceBetween direction="horizontal" size="xs">
                            <Button iconName="refresh" variant="icon" disabled={loading} onClick={getRepoRequests} />
                            < Pagination
                                {...paginationProps}
                                disabled={loading}
                                openEnd={false}

                            />
                        </SpaceBetween>
                    </Box>
                }
                empty={
                    <Box
                        margin={{ vertical: "xs" }}
                        textAlign="center"
                        color="inherit"
                    >
                        <b>No requests</b>
                    </Box>
                }
                />
}