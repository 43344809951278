import React from 'react';
import { Link } from 'react-router-dom';
import { Box, Grid, Header, Icon, SegmentedControl } from '@amzn/awsui-components-react';
import { User } from 'Core/user';
import iProServe from "../../media/images/proserve.png"
// Styles
import css from "Style/components/service-header.scss";

class ServiceHeader extends React.Component {
    constructor(props) {
        super(props)
     
        this.state = {
            theme: props.theme
        }
    }

    render() {
        return (
            <header 
                id="page-header" 
                class="awsui-grid" 
            >
                <nav>
                    <Grid 
                        gridDefinition={[
                            { colspan: 3 },
                            { colspan: 4, push:{ xxs: 5 } }
                        ]} 
                        className="header-title-container"
                    >
                        <Box padding={{ top: "xxs" }}>
                            <Link to="/" className="header-logo-item">
                                <div className="header-logo-item__content">
                                    <Icon className="full-logo" url={iProServe} size="big" alt="AWS Professional Services"></Icon>
                                </div>
                            </Link>
                        </Box>
                        <Box padding={{ top: "xxs", right: "xs" }}>
                            <SegmentedControl
                            selectedId={this.state.theme}
                            onChange={({ detail }) => {
                                    this.setState({ theme: detail.selectedId })
                                    this.props.updateTheme(detail.selectedId)
                                }
                            }
                            label="Theme picker"
                            options={[
                                { text: "Light Mode", id: "light" },
                                { text: "Dark Mode", id: "dark" }
                            ]}
                            />
                        </Box>
                    </Grid>
                </nav>
            </header>
        )
    }
}

export default ServiceHeader;