import { searchRepos } from "./search";

// Get the repo with the given url and manager if it exists
// - repoUrl: Url of the repository
// - managerAlias: Owning manager of the repository
export async function getRepo(
    repoUrl,
    managerAlias,
) {

    // We are using the search api for this call for now
    const searchParams = {
        searchText: repoUrl,
        managers: [managerAlias]
    };

    const searchResult = await searchRepos(searchParams);

    return searchResult?.repos?.shift();
}
