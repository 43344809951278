import { useEffect, useState } from 'react';

import { BreadcrumbGroup, ColumnLayout, Header, SpaceBetween } from "@amzn/awsui-components-react"
import { DataAccessService } from '../../core/api';
import { User } from '../../core/user';
import { getDelegations } from '../../helpers/delegation';
import { useNavigate } from 'react-router-dom';
import { DelegatedByUserTable } from '../../components/delegation-ui/DelegatedByUserTable';
import { DelegatedToUserTable } from '../../components/delegation-ui/DelegatedToUserTable';
import { FlashbarPublisher } from '../../components/FlashbarPublisher';
import { isAdmin } from "../../helpers/isAdmin";

export const Delegation = ({ }) => {

    const [delegatedByUser, setDelegatedByUser] = useState([]);
    const [delegatedToUser, setDelegatedToUser] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const navigate = useNavigate();

    const fetchDelegations = async () => {
        const client = new DataAccessService();
        const user = new User();
        try {
            setIsLoading(true)
            let result = await getDelegations(client, {}, {}, `${window.location.protocol}//${window.location.host}/delegations`);
            let delegatedByUser = [];
            let delegatedToUser = [];
            result.forEach((delegation) => {
                // EDS expects and returns lower-cased aliases, hence the value on the left-hand side of this condition will always be lower-cased.
                // Lowercasing the value on the right just in case there is a userId that does not conform to this.
                if (delegation["Manager"]["Alias"] === user.userId.toLowerCase()) {
                    delegatedByUser.push(delegation["Delegate"]);
                } else {
                    delegatedToUser.push(delegation["Manager"]);
                }
            });
            setDelegatedByUser(delegatedByUser);
            setDelegatedToUser(delegatedToUser);
            setIsLoading(false)
        } catch (e) {
            FlashbarPublisher.setItems([{
                id: "fetch_delegations_error",
                type: "error",
                dismissible: true,
                dismissLabel: "Dismiss message",
                onDismiss: FlashbarPublisher.clear,
                content: "There was an error fetching your Delegations."
            }]);
            setIsLoading(false)
        }
    };

    useEffect(() => {
        fetchDelegations();
    }, []);

    return (
        <div data-testid={"delegation"}>
            <SpaceBetween direction='vertical' size='s'>
                <BreadcrumbGroup
                    test-id="breadcrumb-group"
                    items={[
                        { text: "Code Defender", href: "/" },
                        { text: "Delegation", href: "/delegation" }
                    ]}
                    ariaLabel="Breadcrumbs"
                    onClick={(detail) => navigate(detail.href)}
                />

                <Header variant="h1">Delegation</Header>
            </SpaceBetween>

            <div data-testid={"delegation-tab"}>
                <ColumnLayout>
                    {isAdmin() && <DelegatedByUserTable tableItems={delegatedByUser} isLoading={isLoading} fetchDelegations={fetchDelegations} />}
                    <DelegatedToUserTable tableItems={delegatedToUser} isLoading={isLoading} />
                </ColumnLayout>
            </div>
        </div>
    );
}
