import { User } from "../core/user";

const AC_FEATURE_ROLE = 'AdminConsoleFeature';
const ADMIN_ROLE = 'Admin';
const DELEGATE_ROLE_PREFIX = 'Delegate{';

// Use the user's roles (obtained from the user-details service) to determine
// if admin-console access should be allowed.
export function isAdminConsoleAccessible() {

    let hasFeatureFlag = false;
    let hasAdminLikeRole = false;

    for (let role of (new User()).roles) {
        if (role === ADMIN_ROLE) {
            hasAdminLikeRole = true;
        } else if (role === AC_FEATURE_ROLE) {
            hasFeatureFlag = true;
        } else if (role.startsWith(DELEGATE_ROLE_PREFIX)) {
            hasAdminLikeRole = true;
        }
    }

    return hasFeatureFlag && hasAdminLikeRole;
}