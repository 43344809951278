import { BreadcrumbGroup, Header, SpaceBetween } from "@amzn/awsui-components-react"
import { RepositoryRequestTable } from '../../components/repository-request/RepositoryRequest';
import { RepoSearchTable } from '../../components/repositories-ui/RepoSearchTable';
import { Navigate } from "react-router-dom";
import { isAdminConsoleAccessible } from "../../helpers/isAdminConsoleAccessible";

export const RulesEngine = ({ }) => {

    return (
        <div data-testid={"rules-engine"}>
            <SpaceBetween direction='vertical' size='s'>
                <BreadcrumbGroup
                    test-id="breadcrumb-group"
                    items={[
                        { text: "Code Defender", href: "/" },
                        { text: "Rules Engine", href: "/rules" }
                    ]}
                    ariaLabel="Breadcrumbs"
                    onClick={(detail) => Navigate(detail.href)}
                />

                <Header variant="h1">Rules Engine</Header>
                <div data-testid={"repository-tab"}>
                    <SpaceBetween size='xxl' direction='vertical'>
                        <RepositoryRequestTable isAdmin={isAdminConsoleAccessible()} />
                        <RepoSearchTable adminView={isAdminConsoleAccessible()} />
                    </SpaceBetween>
                </div>
            </SpaceBetween>
        </div>
    );
}