//React
import React, {Component} from "react";

// Components
import ServiceAppLayout from 'Component/ServiceAppLayout.jsx';

// Polaris
import { Icon } from '@amzn/awsui-components-react';

// Styles
import css from "Style/pages/page-not-found.scss";


//Components
class PageNotFound extends Component {
  render() {
    return (
      <ServiceAppLayout
          page="PageNotFound"
          content={<Content />}
          navigationOpen={true}
      />
    );
  }
}

// The content in the main content area of the App layout
const Content = () => (
  <div className="awsui-grid awsui-util-p-s generic-not-found">
    <div className="generic-not-found__container custom-awsui-util-pt-xxxl awsui-row">
      <div className="col-xxs-12">
        <div className="generic-not-found__content col-xxxs-12 align-center">
          <div className="generic-not-found__header">
            <h1>404</h1>
          </div>
        </div>
        <div className="generic-not-found__content col-xxxs-12 align-center">
          <div className="align-center color-primary">
            <h1>Sorry, this URL does not exist or is no longer available.</h1>
          </div>
        </div>
      </div>
    </div>
	</div>
);

export default PageNotFound;