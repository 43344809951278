import { useState } from "react";

import { AppLayout } from "@amzn/awsui-components-react";

import { ServiceNavigation } from "../../components/service-navigation";
import { FlashbarDisplay } from "../../components/FlashbarPublisher";
import { RulesEngine } from "./RulesEngine";

export const RulesEnginePage = () => {
    const [navigationOpen, setNavigationOpen] = useState(false);

    return (
        <div
            style={{
                paddingTop: '60px',
                paddingBottom: '30px'
            }}>
            <AppLayout
                contentType="default"
                navigation={<ServiceNavigation />}
                navigationOpen={navigationOpen}
                onNavigationChange={({ detail }) => setNavigationOpen(detail.open)}
                notifications={<FlashbarDisplay />}
                toolsHide={true}
                headerSelector='#page-header'
                footerSelector='#page-footer'
                stickyNotifications={true}
                content={
                    <div style={{ minHeight: '100vh' }}>
                        <RulesEngine />
                    </div>
                }
            />
        </div>
    )
};
