//React
import React, { Component, Fragment, useEffect, useState } from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';

//Pages
import MetricsHomePage from 'Page/MetricsHomePage';
import ServiceHomePage from 'Page/ServiceHomePage';
import { RegistrationDetailsPage } from "Page/registration-details/RegistrationDetailsPage";
import PageNotFound from 'Page/PageNotFound';

//Components
import ServiceHeader from "Component/ServiceHeader"
import ServiceFooter from "Component/ServiceFooter"
import ErrorBoundary from './ErrorBoundary'

//Styles
import { applyMode, Mode } from '@amzn/awsui-global-styles';

//Util
import { LocalStorage } from "Core/storage";
import { Amplify, Auth } from 'aws-amplify';
import { config } from './amplifyConfig';

import '@amzn/awsui-global-styles/polaris.css';
import { isInternalUser } from "./helpers/isInternalUser";
import getCurrentUserDetails from './helpers/getCurrentUserDetails';
import { DataAccessService } from './core/api';
import { User } from './core/user';
import { INSTALL_STATUS, USER_DETAILS } from './core/user/user';
import { DashboardPage } from './pages/dashboard/DashboardPage';
import { Personal } from './pages/dashboard/personal/Personal';
import { Reports } from './pages/dashboard/reports/Reports';
import { Risks } from './pages/dashboard/risks/Risks';
import { getInstallationStatus } from './helpers/get-installation-status';
import DefaultRedirection, { AUTH_REDIRECT } from './components/DefaultRedirection';
import { DelegationPage } from './pages/delegation/DelegationPage';
import { RulesEnginePage } from './pages/rules-engine/RulesEnginePage';

Amplify.configure({
	Auth: config
})

function UpdateUser({ theme, updateTheme }) {
	const [userLoaded, setUserLoaded] = useState(false);

	useEffect(() => {
		const authUser = async () => {
			let cognitoUser;
			try {
				cognitoUser = await Auth.currentAuthenticatedUser();
			} catch (e) {
				if (window.location.pathname !== '/') {
					localStorage.setItem(AUTH_REDIRECT, window.location.pathname);
				}
				Auth.federatedSignIn();
				return;
			}

			try {
				const client = new DataAccessService({
					baseURL: `${window.location.protocol}//${window.location.host}`
				});

				const getUserDetails = getCurrentUserDetails(client, (new User()).userId);
				const getInstallStatus = getInstallationStatus();

				let [userDetails, installStatus] = await Promise.all([
					getUserDetails,
					getInstallStatus
				]);

				LocalStorage.set(INSTALL_STATUS, installStatus);
				LocalStorage.set(USER_DETAILS, userDetails)
			} catch (e) {
				console.warn(`Error getting and storing user details - `, e);
			}

			setUserLoaded(true);
		};
		authUser();
	}, []);

	if (userLoaded) {
		return (
			<BrowserRouter forceRefresh={false}>
				<ErrorBoundary>
					<Fragment>
						<ServiceHeader theme={theme} updateTheme={updateTheme} />
						<div className="page">
							<Routes>
								<Route index element={<DefaultRedirection />} />
								<Route exact path="/installation" element={<ServiceHomePage />} />
								{isInternalUser() && <Route exact path="/view_metrics" element={<MetricsHomePage />} />}
								<Route exact path="/registration_details" element={<RegistrationDetailsPage />} />
								<Route path="/dashboard" element={<DashboardPage />}>
									<Route index element={<Personal />} />
									<Route path="reports" element={<Reports />} />
									<Route path="risks" element={<Risks />} />
								</Route>
								<Route path="/delegation" element={<DelegationPage />} />
								<Route path="/rules" element={<RulesEnginePage />} />
								<Route element={<PageNotFound />} />
							</Routes>
						</div>
						<ServiceFooter />
					</Fragment>
				</ErrorBoundary>
			</BrowserRouter>
		);
	} else {
		return (
			<div className="App">
				<div className='loader'></div>
			</div>
		)
	}
}

class Router extends Component {
	constructor(props) {
		super(props);

		this.state = {
			theme: ''
		}

		let osDarkMode, theme
		if (LocalStorage.get("theme")) {
			osDarkMode = (LocalStorage.get("theme") === "dark")
		}
		else {
			osDarkMode = window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches
		}
		theme = osDarkMode ? "dark" : "light"

		this.updateTheme(theme)
	}

	updateTheme = (mode) => {
		if (mode) {
			if (mode === "light") {
				applyMode(Mode.Light);
			}
			else if (mode === "dark") {
				applyMode(Mode.Dark);
			}
			this.setState({ theme: mode })
			LocalStorage.set("theme", mode)
		}
	}

	render() {
		return (
			<UpdateUser
				theme={this.state.theme}
				updateTheme={this.updateTheme} />
		);
	}
}

export default Router;