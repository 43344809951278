import { User } from "../core/user";

const DASHBOARD_FEATURE_ROLE = 'NewDashboardFeature';

// Use the user's roles (obtained from the user-details service) to determine
// if new-dashboard access should be allowed.
export function isNewDashboardAvailable() {

    let hasFeatureFlag = false;

    for (let role of (new User()).roles) {
        if (role === DASHBOARD_FEATURE_ROLE) {
            hasFeatureFlag = true;
        }
    }

    return hasFeatureFlag;
}