import {
    Badge,
    Box,
    BreadcrumbGroup,
    Button,
    ColumnLayout,
    Container,
    FormField,
    Header,
    Input,
    LineChart,
    Link,
    Pagination,
    SegmentedControl,
    SpaceBetween,
    Spinner,
    Table,
    Tabs,
} from '@amzn/awsui-components-react';
import { Dashboard } from './Dashboard';
import { Component } from 'react';
import MetricsAppLayout from 'Component/MetricsAppLayout.jsx';
import { personalColumnDefinitions } from './personal/Personal';

export class DashboardPage extends Component {

    constructor(props) {
        super(props)
        this.state = {
            flashBarError: false,
            flashBarSuccess: false,
            header: "",
            userContent: "",
        }
        this.headerUpdater = this.headerUpdater.bind(this)
    }

    headerUpdater(header, data) {
        this.setState({
            header: header,
            userContent: <Table
                header={<Header>Aggregated Data for {header}'s Direct Reports</Header>}
                columnDefinitions={personalColumnDefinitions}
                items={data}
            />
        })
    }

    render() {
        return (
            <MetricsAppLayout
                className="awsui-util-no-gutters"
                page="MetricsHomePage"
                content={<div style={{ minHeight: '100vh' }}><Dashboard {...this.props} headerUpdater={this.headerUpdater} /></div>}
                toolsHide={true} />
        );
    }
}