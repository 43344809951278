import { Box, Link } from "@amzn/awsui-components-react";

export const columnDefinitions = [
    {
        id: "name",
        header: "Name",
        cell: item =>
            <Link href={`https://phonetool.amazon.com/users/${item["Alias"]}`}>
                {`${item["DisplayName"]}`}
            </Link>,
        sortingField: "DisplayName"
    },
    {
        id: "alias",
        header: "Alias",
        cell: item => item["Alias"],
        sortingField: "Alias"
    },
    {
        id: "jobTitle",
        header: "Job title",
        cell: item => item["BusinessTitle"],
        sortingField: "BusinessTitle"
    }
];

export const EmptyState = (props) => {
    return (
        <Box
            margin={{ vertical: "xs" }}
            textAlign="center"
            color="inherit"
        >
            {props.children}
        </Box>
    );
};
