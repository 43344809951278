import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";

import { SideNavigation } from "@amzn/awsui-components-react";
import { isInternalUser } from "../../helpers/isInternalUser";
import { isAdminConsoleAccessible } from "../../helpers/isAdminConsoleAccessible";
import { isNewDashboardAvailable } from "../../helpers/isNewDashboardAvailable";
import { isDefenderInstalled } from "../../helpers/isDefenderInstalled";

export const ServiceNavigation = () => {
    const location = useLocation();
    const navigate = useNavigate();

    let dynamicItems = [];
    if (!isDefenderInstalled()) {
        dynamicItems.push({
            type: "link",
            text: "Getting started",
            href: "/installation"
        });
    }
    if (isInternalUser()) {
        let dashboardPath = isNewDashboardAvailable() ? "/dashboard" : "/view_metrics";
        dynamicItems.push({
            type: "link",
            text: "Dashboard",
            href: dashboardPath
        });
    };

    if (isAdminConsoleAccessible()) {
        dynamicItems.push({ type: "link", text: "Delegation", href: "/delegation" });
    }

    if (isAdminConsoleAccessible() || isNewDashboardAvailable()) {
        dynamicItems.push({ type: "link", text: "Rules Engine", href: "/rules" });
    }

    return (
        <SideNavigation
            id={"SideNavigation"}
            activeHref={location.pathname}
            header={{ href: "/installation", text: "Code Defender" }}
            onFollow={event => {
                if (!event.detail.external) {
                    event.preventDefault();
                    navigate(event.detail.href);
                }
            }}
            items={[
                ...dynamicItems,
                { type: "divider" },
                {
                    type: "section",
                    text: "Helpful resources",
                    items: [
                        {
                            type: "link",
                            text: "FAQ",
                            href: "https://w.amazon.com/bin/view/AWS/Teams/GlobalServicesSecurity/Engineering/CodeDefender/UserHelp/",
                            external: true
                        },
                        {
                            type: "link",
                            text: "User onboarding",
                            href: "https://w.amazon.com/bin/view/AWS/Teams/GlobalServicesSecurity/Engineering/CodeDefender/UserOnboarding/",
                            external: true
                        },
                        {
                            type: "link",
                            text: "About Code Defender",
                            href: "https://w.amazon.com/bin/view/AWS/Teams/GlobalServicesSecurity/Engineering/CodeDefender/",
                            external: true
                        }
                    ]
                }
            ]}
        />
    );
}
